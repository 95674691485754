"use client"

import { EventEmitter } from "events"

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import * as clib from "@clerk/nextjs"
import { useLocalStorage, useSessionStorage } from "react-use"

import { fetchUpdateUserAssociations, getLocation } from "@/app/actions/user"


import {
  checkSession,
  setActiveEscort,
  setActiveEscortProfile,
} from "./lib/fetchers/user"

import { BookingRead } from "./lib/schema/openapi-generator/ts/models/BookingRead"
import { ChatRead } from "./lib/schema/openapi-generator/ts/models/ChatRead"
import { Customer } from "./lib/schema/openapi-generator/ts/models/Customer"
import { Escort } from "./lib/schema/openapi-generator/ts/models/Escort"
import { EscortProfile } from "./lib/schema/openapi-generator/ts/models/EscortProfile"
import { SellerRead } from "./lib/schema/openapi-generator/ts/models/SellerRead"
import {
  UserAccount,
  UserAccountSignedupAsEnum,
} from "./lib/schema/openapi-generator/ts/models/UserAccount"
import { AffiliateProfile } from "./lib/schema/openapi-generator/ts/models/AffiliateProfile"
import { AffiliateReferralCode } from "./lib/schema/openapi-generator/ts/models/AffiliateReferralCode"
import { AffiliateReferralCodeUsage } from "./lib/schema/openapi-generator/ts/models/AffiliateReferralCodeUsage"
import { ChatMessage } from "./lib/schema/openapi-generator/ts/models/ChatMessage"
import { PayOwing } from "./lib/schema/openapi-generator/ts/models/PayOwing"
import { GalleryItem } from "./lib/schema/openapi-generator/ts/models/GalleryItem"
import { Service } from "./lib/schema/openapi-generator/ts/models/Service"
import { PayIncoming } from "./lib/schema/openapi-generator/ts/models/PayIncoming"
import { PayWallet } from "./lib/schema/openapi-generator/ts/models/PayWallet"
import { TimeSlot } from "./lib/schema/openapi-generator/ts/models/TimeSlot"
import { logger, SmCache } from "./lib/utils"
import { PayOutgoing } from "./lib/schema/openapi-generator/ts/models/PayOutgoing"
import Loading from "./app/help/loading"


export type ClerkUserData = {
  crx?:any,
  activeAffiliateProfileId: number | null
  activeAffiliateProfie: AffiliateProfile | null
  activeCustomer: Customer | null
  activeUserAccountId: number | null
  activeUserAccount: UserAccount | null
  activeCustomerId: number | null
  activeEscort: Escort | null
  activeEscortId: number | null
  activeEscortProfile: EscortProfile | null
  activeEscortProfileId: number | null
  activeSeller: SellerRead | null
  activeSellerId: number | null
  bookings: { [key: number]: BookingRead }
  bookingIds: number[]
  chats: { [key: number]: ChatRead }
  chatIds: number[]
  clerkUserId: string
  email: string
  error?: string
  escortIds: number[]
  escortProfileIds: number[]
  escortProfiles: { [key: number]: EscortProfile }
  escorts: { [key: number]: Escort }
  mobile: string
  modBookingSocialEnabled: boolean
  modProfileSocialEnabled: boolean
  pendingReviews: number[]
  pin: string
  postcode?: string
  postcodeLabel?: any
  sellerIds: number[]
  sellers: { [key: number]: SellerRead }
  signupProgress: string
  userId: string
  userType: string
  cache: {
    userAccount: { [key: number]: UserAccount }
    seller: { [key: number]: SellerRead }
    escort: { [key: number]: Escort }
    escortProfile: { [key: number]: EscortProfile }
    affiliateProfile: { [key: number]: AffiliateProfile }
    affiliateReferralCodeUsage: { [key: number]: AffiliateReferralCodeUsage }
    customer: { [key: number]: Customer }
    booking: { [key: number]: BookingRead }
    chatMessage: { [key: number]: ChatMessage }
    payOwing: { [key: number]: PayOwing }
    chat: { [key: number]: ChatRead }
    service: { [key: number]: Service },
    payIncoming: { [key: number]: PayIncoming }
    payWallet: { [key: number]: PayWallet }
    payOutgoing:  { [key: number]: PayOutgoing }
    affiliateReferralCode: { [key: number]: AffiliateReferralCode }
    //PayOutgoing: { [key: number]: PayOutgoing }
    galleryItem: { [key: number]: GalleryItem }
    timeSlot: { [key: number]: TimeSlot }
  }
}



function getAllIds(obj: any) {
  const result: string[] = []

  function checkKeyAndId(key: string, value: any) {
    // Check if the key is 10 characters/digits long
    if (/^\d{9}$/.test(key)) {
      result.push(key)
    }

    // Check if the value is an object, then recurse
    if (typeof value === "object" && value !== null) {
      for (let subKey in value) {
        if (value.hasOwnProperty(subKey)) {
          checkKeyAndId(subKey, value[subKey])
        }
      }
    }

    // Check if the value is a string that is 10 characters/digits long
    if (typeof value === "string" && /^\d{10}$/.test(value)) {
      result.push(value)
    }
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      checkKeyAndId(key, obj[key])
    }
  }

  return result
}

interface JerkContextType {
  routerPush: (route: string) => Promise<any>
  session: ClerkUserData
  setSession: (session: ClerkUserData) => void
  refreshSession: Function
  smCache: typeof SmCache
  eventEmitter: EventEmitter
  setLocation: any
  setFilters: any
  filters: any
  location: Awaited<ReturnType<typeof getLocation>> | undefined
  updateActiveEscortAndProfile: Function
  setSignupAs: Function
  signupAs: UserAccountSignedupAsEnum | null | undefined,
  isLoaded: boolean
}

const JerkContext = createContext<JerkContextType | undefined>(undefined)

function fastHash(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString(16)
}

function useEventSource(url: string) {
  const eventSourceRef = useRef<EventSource | null>(null)
  const eventEmitterRef = useRef<EventEmitter | null>(null)

  useEffect(() => {
    let attempt = 0
    const maxAttempts = 100
    const heartbeatInterval = 10000 // 30 seconds

    const createEventSource = () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close()
      }
      if (eventEmitterRef.current) {
        eventEmitterRef.current.removeAllListeners()
      }

      const source = new EventSource(url)
      logger.log("EventSource created")
      const eventEmitter = new EventEmitter()
      eventEmitter.setMaxListeners(Infinity)

      source.onmessage = (event) => {
        const dx = JSON.parse(event.data)
        eventEmitter.emit(dx.channel, dx.message)
      }

      source.onerror = () => {
        console.error("EventSource failed, retrying...")
        source.close()
        if (attempt < maxAttempts) {
          attempt++
          setTimeout(() => {
            createEventSource()
          }, Math.min(1000)) // exponential backoff with max delay of 30s
        } else {
          console.error("Max reconnect attempts reached.")
        }
      }

      // Heartbeat to keep connection alive
      const heartbeat = setInterval(() => {
        if (source.readyState === EventSource.OPEN) {
          source.dispatchEvent(new Event("heartbeat"))
        }
      }, heartbeatInterval)

      source.addEventListener("heartbeat", () => {
        logger.log("Heartbeat received")
      })

      eventSourceRef.current = source
      eventEmitterRef.current = eventEmitter
    }

    createEventSource()

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close()
      }
      if (eventEmitterRef.current) {
        eventEmitterRef.current.removeAllListeners()
      }
    }
  }, [url])

  return {
    eventSource: eventSourceRef.current,
    eventEmitter: eventEmitterRef.current,
  }
}

export const forceCorrectPage = async (res: ClerkUserData, routerPush: (path: string) => void, pathname: string, search: string) => {
  if (!res?.clerkUserId) return null
  if (!res.userType) routerPush(`/user-selector`)

  if (res && res.userType) {
    if (res.signupProgress !== "complete") {
      const targetPath = `/create/${res.userType}/${res.signupProgress}`
      if (
        !pathname?.includes(targetPath) &&
        !pathname?.includes("/sign-up") &&
        !pathname?.includes("/sign-in") &&
        !pathname?.includes("/help") &&
        !pathname?.includes("/verification")
      ) {
        let path = `${targetPath}`
        if (search.length > 1) path = path + "?" + search
        return routerPush(path)
      }
    } else if (
      pathname?.startsWith("/create/") ||
      pathname?.startsWith("/after-login")
    ) {
      if (res.userType === UserAccountSignedupAsEnum.Affiliate) {
        return routerPush("/profile/affiliate")
      }

      if (res.userType === UserAccountSignedupAsEnum.Customer) {
        return routerPush("/profile/customer/profile")
      }
      if (res.userType === UserAccountSignedupAsEnum.Escort) {
        if (!res.activeEscort?.ageRange)
          return routerPush(
            `/profile/escort/profile/${res.activeEscortProfileId}?onboarding=true`
          )
        return routerPush(
          `/profile/escort/profile/${res.activeEscortProfileId}/settings`
        )
      }
      if (
        res.userType === UserAccountSignedupAsEnum.Agency ||
        res.userType === UserAccountSignedupAsEnum.Site
      ) {
        return "/profile/agency"
      }

      return null
    }
    if (
      res.pendingReviews?.length &&
      !pathname.includes("wallet") &&
      !pathname.includes("check-in") && 
      !pathname.includes("profile/customer/profile")
    ) {
      
      routerPush(
        `/profile/customer/bookings/${res.pendingReviews[0]}`
      )
    }    
  }
}


export const JerkProvider = ({ children }: { children: ReactNode }) => {

  const [signupAs, setSignupAs] = useLocalStorage<UserAccountSignedupAsEnum>("signupAs")

  const [sessionStorage, setSessionStorage] = useSessionStorage<ClerkUserData>("jerkSession")
  const session = useRef(sessionStorage ?? {})
  const setSession = (sx: ClerkUserData) => {
    setSessionStorage(sx)
    session.current = sx
  }
  const [loaded, setLoaded] = useState(false)
  const userx = clib.useUser()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()
  const [sessionReloading, setSessionReloading] = useState(false)
  const { eventEmitter } = useEventSource("/api/events")
  const [location, setLocation] = useState<Awaited<ReturnType<typeof getLocation>>>()
  const [filters, setFilters] = useState<Record<string, any>>({
    escort_type: ["escort"],
    category: { Female: "Female" },
  })
  let pendingUpdatePromise: Promise<any>[] = []

  const routerPush = (route: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let timeoutId: NodeJS.Timeout;
      timeoutId = setTimeout(() => {
        router.push(route);
        resolve();
      }, 10000); // 10 seconds delay

      const push = () => {
        if (sessionReloading) {
          setTimeout(push, 100)
        } else {
          clearTimeout(timeoutId);
          router.push(route)
          resolve()
        }
      }
      push()
    })
  }


  const handleSignUpAs = async (val: UserAccountSignedupAsEnum) => {
    setSignupAs(val)
    return await updateSession(true)
  }

  const updateSession = async (evict = false) => {
    setSessionReloading(true)
    if (pendingUpdatePromise.length > 0) {
      return Promise.all(pendingUpdatePromise)
    }

    const action = async () => {
      if (eventEmitter) eventEmitter.emit("updatingSession")
      if (!evict) evict = !!searchParams.get("evict")

      const newSession = await fetchUpdateUserAssociations(
        (session?.current?.userType as UserAccountSignedupAsEnum) || signupAs,
        evict
      )
      //return setTimeout(action,1000)
      let sx = {}
      if ( fastHash(JSON.stringify(newSession)) !== fastHash(JSON.stringify(session))) {
          setSession(newSession)
          sx = newSession
        
      }

      setSessionReloading(false)

      return sx
    }

    const actionPromise = action().finally(() => {
      // Clear pending promises once resolved
      pendingUpdatePromise = []
    })

    pendingUpdatePromise.push(actionPromise)

    return Promise.all(pendingUpdatePromise)
  }

  const updateActiveEscortAndProfile = async (
    escortProfileId: number,
    escortId: number
  ) => {
    setActiveEscort(session.current.clerkUserId, escortId)
    setActiveEscortProfile(session.current.clerkUserId, escortProfileId)
    await updateSession(true)
  }

  const handleEviction = (data: any) => {
    const ids = getAllIds(session.current)
    const found = ids.filter((x) => data.includes(x)).length
    if (
      found ||
      data.includes(session.current.clerkUserId) ||
      data.includes(session.current.activeEscortProfileId + "") ||
      data.includes(session.current.activeCustomerId + "")
    ) {
      logger.log("handling Eviction")
      updateSession(true)
    }
  }


  //Refresh if tab is Changed
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!["profile/escort/profile/"].filter((x) => pathname.indexOf(x) > -1).length) {
          updateSession(true)
        }
      } else {
        logger.log("Tab is inactive")
        // Add your logic here for when the tab becomes inactive
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange)
    if (eventEmitter) eventEmitter.on("evictions", handleEviction)
    
      
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
      if (eventEmitter) eventEmitter.off("evictions", handleEviction)
    }
  }, [eventEmitter])

  // Set Location
  useEffect(() => {
    getLocation().then((x) => {
      setLocation(x)
    })
  }, [])

  useEffect(()=>{
    setLoaded((userx.isLoaded && userx.isSignedIn && !!session.current?.clerkUserId) || !userx.isSignedIn)
  },[session, userx, userx.isLoaded, userx.isSignedIn, session.current,userx.isSignedIn])

  useEffect(() => {

    const fx =  async (sx:ClerkUserData) => {
      await forceCorrectPage(sx, routerPush, pathname, new URLSearchParams(searchParams).toString())
      //@ts-ignore
      window.jerkSession = sx       
    }

    if (session && session.current?.clerkUserId) {
      const check = async () => {
        const x = await checkSession(
          session.current.clerkUserId,
          await fastHash(JSON.stringify(session))
        )
        if (!x) {
          updateSession().then((a:any)=>fx(a[0]))
        } else {
          fx(session.current)
        }
      }
      check()
    } else {
      updateSession().then((a:any)=>fx(a[0])).then( () => fx(session.current))
    }

    
    
  }, [pathname])


  const smCache = useMemo(() => {
    const cache: { [key: string]: { ttl: number, val: any } } = {};
    const functionIdMap = new Map<Function, string>();
    let idCounter = 0;

    const generateUniqueId = () => `uniqueId_${idCounter++}`;


    return async (func: Function, ...args: any[]) => {
      return await func(...args);
      // let funcId = functionIdMap.get(func);
      // if (!funcId) {
      //   funcId = generateUniqueId();
      //   functionIdMap.set(func, funcId);
      // }

      // const key = funcId+JSON.stringify(args);
      // const cacheEntry = cache[key+"@@@"];

      // logger.log(key, cacheEntry, cache)
      // if (cacheEntry) {
      //   logger.log("HIT");
      //   return cacheEntry.val;
      // }

      // const val = await func(...args);
      // cache[key+"@@@@"] = { ttl: Date.now() -100000, val }; // Cache expiry set to 5 seconds
      // logger.log(key, cacheEntry, cache)

      // Object.values(cache).map(x => {
      //   if (x.ttl <= Date.now()) {
      //     delete cache[key];
      //   }
      // })


      // return val;
    };
  }, [])



  useEffect(() => {
    if (!session) updateSession()
  }, [])
  if (!eventEmitter) return <Loading></Loading>

  return (
    <JerkContext.Provider
      value={{
        setSignupAs: handleSignUpAs,
        signupAs,
        isLoaded: userx.isLoaded,
        routerPush,
        eventEmitter,
        location,
        setLocation,
        smCache,
        filters,
        setFilters,
        session: session.current,
        setSession,
        refreshSession: async () => {
          return await updateSession(true)
        },
        updateActiveEscortAndProfile,
      }}
    >
      <LoadingProgress loading={sessionReloading && loaded} />
      {loaded &&
        <>
          {children}
        </>
      }

    </JerkContext.Provider>
  )
}

const LoadingProgress = ({ loading }: { loading: boolean }) => {
  const elementRef = useRef<HTMLDivElement>(null)
  return (
    <>
      {loading && (
        <div
          ref={elementRef}
          style={{ height: 2 }}
          className="fixed left-0 top-16 z-[100] w-screen"
        >
          <div className="h-full w-full bg-background overflow-hidden">
            <div className="progress w-full h-full bg-foreground left-right"></div>
          </div>
        </div>
      )}
    </>
  )
}

export const useJerkSession = () => {
  const context = useContext(JerkContext)
  if (context === undefined) {
    throw new Error("useJerkSession must be used within a JerkProvider")
  }
  return context
}

